import Overrides from 'data/overrides.json'
import {
    getDefaultMileage,
    getDefaultProduct,
    getDefaultTerm,
    getPrettyFuel,
    getPrettyGear,
} from 'utils/displayHelpers'
import { mergeObjects } from 'utils/global'
import Destacados from 'data/destacados.json'

export const ofertas60Aniversario = [] // 72 meses

export const industrial = ['OC', 'CP', 'PV', 'RV', 'WV', 'CV', 'BU', 'CC', 'CH', 'BH', 'TI', 'DS']
export const premiumBrands = [
    'ABARTH',
    'AUDI',
    'BMW',
    'CUPRA',
    'JAGUAR',
    'JEEP',
    'LAND ROVER',
    'LEXUS',
    'MERCEDES BENZ',
    'MG',
    'VOLVO',
    'POLESTAR',
]
export const luxuryBrands = ['BENTLEY', 'FERRARI', 'MASERATI', 'PORSCHE']

export const checkIfBlacklisted = (campaign, config) => {
    const campaignID = campaign.campaignID
    const brand = campaign.brand
    const profile = config.profile
    const model = `${campaign.brand}-${campaign.model}`.toUpperCase()

    if (profile === 'particulares') {
        if (blackList.individual.campaigns.indexOf(campaignID) > -1) return true
    }
    if (profile === 'empresas') {
        if (blackList.sme.campaigns.indexOf(campaignID) > -1) return true
    }
    if (profile === 'autonomos') {
        if (blackList.soletrader.campaigns.indexOf(campaignID) > -1) return true
    }
    if (blackList.global.campaigns.indexOf(campaignID) > -1) return true
    return false
}

export const blackList = {
    individual: {
        brands: [
            'RENAULT',
            'DACIA',
            'DS',
            'OPEL',
            'PEUGEOT',
            'CITROEN',
            'NISSAN',
            'SKODA',
            'FIAT',
            'JEEP',
            'ALFA ROMEO',
            'DODGE',
            'RAM',
            'LANCIA',
            'ABARTH',
            'MERCEDES',
            'MERCEDES BENZ',
        ],
        model: ['VOLKSWAGEN-T-CROSS'],
        campaigns: [
            4705, 4714, 4741, 4744, 4743, 4745, 4755, 4781, 4952, 4953, 4877, 4969, 4970, 4971, 4943, 4944, 5009, 5010,
            5007, 5065, 5082, 5079, 5241, 5239, 5231, 5367, 5368, 5369, 5437, 5430, 5429, 5435, 5436,
        ],
    },
    soletrader: {
        campaigns: [4705, 4714],
        brands: [],
    },
    sme: {
        campaigns: [],
        brands: [],
    },
    global: {
        // , 'VOLKSWAGEN-T-ROC'
        model: ['VOLKSWAGEN-GOLF'],
        brand: ['SKODA'],
        campaigns: [
            5171, 5268, 3832, 3833, 3823, 3828, 3831, 4125, 4126, 4127, 4181, 4160, 4203, 4326, 4327, 4359, 4459, 4523,
            4609, 4611, 4612, 40000008, 4312, 4526, 5182, 5203, 5380, 5292, 5229,
        ],
    },
    destacados: {
        home: [4806],
        autonomos: [],
        empresas: [],
        particulares: [],
    },
}

export const whiteList = {
    global: {
        campaigns: [
            4886, 4752, 4913, 4914, 4919, 4888, 4889, 4941, 4976, 4993, 4943, 4944, 5009, 5010, 5055, 5056, 5054, 5053,
            5065, 5082, 5079, 5109, 5110, 5158, 5159, 5161, 5263, 5298,
        ],
        prioritize: [5137, 5086, 4884],
    },
    individual: {
        campaigns: [
            4745, 4744, 4743, 4742, 4746, 4680, 4767, 4768, 4771, 4772, 4786, 4788, 4789, 4840, 4841, 4756, 4487, 4682,
            5098, 5105, 5103, 5102, 5101, 5104, 5100, 5124, 5128, 5126, 5127, 5125, 5196, 5193, 20000010, 5070, 5137,
            5090, 20000011, 20000012, 20000004, 20000005, 20000003, 20000007, 20000008, 20000009, 20000005, 5268, 5270,
            5272, 5274, 5276, 5277, 5289, 5290, 5295, 5296, 5285, 5286, 5281, 5291, 5288, 5287, 5283, 5298, 5292, 5293,
            5297, 5362, 5415, 5414, 5394, 5303, 5304, 5424, 5425, 5458, 5459,
        ],
        model: [],
    },
    soletrader: {
        campaigns: [],
        model: [],
    },
    sme: {
        campaigns: [],
        model: [],
    },
}

const movistarCarList = {
    model: [], // 'SKODA-KAMIC' ,'SKODA-KAROQ', 'NISSAN-MICRA', 'KIA-XCEED', 'CITROEN-C4', 'CITROEN-C3 AIRCROSS', 'SEAT-ARONA', 'SEAT-ATECA'
}

export const specialPrice = [
    4332, 4486, 4487, 4544, 4546, 4603, 4680, 4684, 4686, 4688, 4725, 4732, 4734, 4737, 4752, 4755, 4761, 4779, 4791,
    4804, 4805, 4808, 4809, 4822, 4827, 4835, 4837, 4842, 4853, 4855, 4857, 4859, 4860, 4862, 4864, 4866, 4868, 4871,
    4872, 4873, 4875, 4877, 4881, 4884, 4901, 4902, 4907, 4909, 4910, 4915, 4919, 4926, 4932, 4938, 4946, 4950, 4951,
    4952, 4953, 4962, 4964, 4967, 4969, 4970, 4971, 4972, 4976, 4979, 4982, 4983, 4988, 5013, 5014, 5016, 5018, 5021,
    5022, 5024, 5027, 5028, 5029, 5031, 5033, 5034, 5035, 5038, 5039, 5045, 5046, 5047, 5052, 5058, 5061, 5062, 5067,
    5071, 5075, 5077, 5079, 5082, 5086, 5095, 5097, 5098, 5103, 5104, 5114, 5123, 5124, 5134, 5135, 5137, 5139, 5140,
    5142, 5147, 5148, 5150, 5151, 5152, 5153, 5154, 5155, 5156, 5158, 5159, 5161, 5165, 5166, 5169, 5170, 5176, 5177,
    5178, 5179, 5180, 5181, 5184, 5185, 5186, 5188, 5189, 5191, 5192, 5193, 5195, 5198, 5200, 5201, 5203, 5204, 5210,
    5211, 5212, 5215, 5216, 5217, 5218, 5219, 5221, 5223, 5224, 5226, 5227, 5229, 5231, 5233, 5235, 5237, 5239, 5241,
    5243, 5245, 5257, 5258, 5259, 5260, 5280, 5281, 5283, 5285, 5286, 5287, 5288, 5289, 5290, 5291, 5292, 5293, 5295,
    5296, 5297, 5298, 5299, 5300, 5301, 5303, 5304, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315,
    5316, 5317, 5318, 5320, 5322, 5324, 5326, 5328, 5330, 5332, 5333, 5334, 5335, 5337, 5338, 5339, 5340, 5342, 5343,
    5344, 5347, 5349, 5353, 5355, 5356, 5357, 5358, 5359, 5361, 5362, 5364, 5365, 5379, 5380,
]

export const filterCampaignsFlexible = function (campaigns, config) {
    let result = {
        campaigns: [],
        brands: [],
    }

    if (config?.tipoVehiculo?.[0] === true && config?.tipoVehiculo?.[1] === false) return result

    const filterByTiempoUso = config?.tiempoUso ? !Object.values(config.tiempoUso).every((x) => x === false) : false
    if (filterByTiempoUso && config?.tiempoUso[0] === false && config?.tiempoUso[1] === false) return result

    const filterByPrice = !Object.values(config.precio).every((x) => x === false)
    const filterByFuel = !Object.values(config.combustible).every((x) => x === false)
    const filterByEtiqueta = !Object.values(config.etiqueta || {}).every((x) => x === false)
    const filterByGear = !Object.values(config.cambio).every((x) => x === false)
    const filterBySegmento = !Object.values(config?.segmento).every((x) => x === false)
    const filterByBrand = config.marca.length > 0

    let productType = config.perfil === 'particulares' ? ['flexible'] : ['flexible10']

    campaigns = campaigns.filter((campaign) => {
        if (productType.indexOf(campaign?.productType) === -1) return false
        if (config?.bajadaPrecio) {
            if (!campaign.bajadaDePrecio) return false
        }
        const defaultProduct = campaign.productType
        const defaultTerm = campaign.defaultTerm
        const defaultMileage = campaign.defaultMileage
        const quoteWithoutVAT = campaign.quotes[defaultProduct][defaultTerm][defaultMileage].monthlyQuote

        if (filterByBrand || filterByFuel || filterByGear || filterByEtiqueta) return false

        if (config?.ultimasUnidades) {
            if (campaign.stock > 9) return false
        }

        if (filterByPrice) {
            let isValid = false
            if (config.precio[3] && quoteWithoutVAT > 450) isValid = true
            if (config.precio[1] && quoteWithoutVAT >= 250 && quoteWithoutVAT <= 300) isValid = true
            if (config.precio[2] && quoteWithoutVAT >= 300 && quoteWithoutVAT <= 450) isValid = true
            if (config.precio[0] && quoteWithoutVAT < 250) isValid = true
            if (!isValid) return false
        }

        if (filterBySegmento) {
            let isValid = false
            if (config.segmento?.['0'] && SUV2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['1'] && Comerciales2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['2'] && Berlina2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['4'] && Monovolumen2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['5'] && Compacto2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['6'] && Turismo2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['3'] && Premium.indexOf(campaign.brand.toUpperCase()) > -1) isValid = true
            if (!isValid) return false
        }
        return true
    })

    campaigns.sort((a, b) => {
        if (
            a.quotes[a.productType][a.defaultTerm][a.defaultMileage].monthlyQuote >
            b.quotes[b.productType][b.defaultTerm][b.defaultMileage].monthlyQuote
        )
            return 1
        if (
            a.quotes[a.productType][a.defaultTerm][a.defaultMileage].monthlyQuote <
            b.quotes[b.productType][b.defaultTerm][b.defaultMileage].monthlyQuote
        )
            return -1
        return 0
    })

    result.campaigns = campaigns
    return result
}

export const filterCampaignsUCL = function (campaigns, config) {
    let result = {
        campaigns: [],
        brands: [],
    }

    if (config?.tipoVehiculo?.[0] === true && config?.tipoVehiculo?.[1] === false) return result

    const filterByTiempoUso = config?.tiempoUso ? !Object.values(config.tiempoUso).every((x) => x === false) : false
    if (filterByTiempoUso && config?.tiempoUso[1] === false && config?.tiempoUso[2] === false) return result

    const filterByPrice = !Object.values(config.precio).every((x) => x === false)
    const filterByFuel = !Object.values(config.combustible).every((x) => x === false)
    const filterByEtiqueta = !Object.values(config.etiqueta || {}).every((x) => x === false)
    const filterByGear = !Object.values(config.cambio).every((x) => x === false)
    const filterBySegmento = !Object.values(config.segmento).every((x) => x === false)
    const filterByBrand = config.marca.length > 0

    campaigns = campaigns.filter((campaign) => {
        if (campaign.productType !== 'ucl') return false

        if (config?.bajadaPrecio) {
            if (!campaign.bajadaDePrecio) return false
        }

        const defaultProduct = 'ucl'
        const defaultTerm = campaign.defaultTerm
        const defaultMileage = campaign.defaultMileage
        const quoteWithoutVAT = campaign.quotes[defaultProduct][defaultTerm][defaultMileage].monthlyQuote
        const model = `${campaign.brand}-${campaign.model}`.toUpperCase()

        if (config.perfil === 'particulares') {
            if (industrial.indexOf(campaign.nolsBodyType) > -1) return false
            if (
                whiteList.individual.model.indexOf(model) === -1 &&
                whiteList.individual.campaigns.indexOf(campaign.campaignID) === -1 &&
                [undefined, -1].indexOf(config?.whiteList?.indexOf(campaign.campaignID)) > -1
            ) {
                if (blackList.individual.brands.indexOf(campaign.brand.toUpperCase()) > -1) return false
                if (blackList.individual.campaigns.indexOf(campaign.campaignID) > -1) return false
                if (blackList.individual.model.indexOf(model) > -1) return false
            }
        } else {
            if (campaign?.onlyIndividuals) return false
        }

        if (config.perfil === 'autonomos') {
            if (
                whiteList.soletrader.model.indexOf(model) === -1 &&
                whiteList.soletrader.campaigns.indexOf(campaign.campaignID) === -1 &&
                [undefined, -1].indexOf(config?.whiteList?.indexOf(campaign.campaignID)) > -1
            ) {
                if (blackList?.soletrader?.brands.indexOf(campaign.brand) > -1) return false
                if (blackList?.soletrader?.campaigns.indexOf(campaign.campaignID) > -1) return false
            }
        }
        if (config.perfil === 'empresas') {
            if (
                whiteList.sme.model.indexOf(model) === -1 &&
                whiteList.sme.campaigns.indexOf(campaign.campaignID) === -1 &&
                [undefined, -1].indexOf(config?.whiteList?.indexOf(campaign.campaignID)) > -1
            ) {
                if (blackList?.sme?.brands.indexOf(campaign.brand) > -1) return false
                if (blackList?.sme?.campaigns.indexOf(campaign.campaignID) > -1) return false
            }
        }

        if (filterByFuel || filterByGear || filterBySegmento || filterByEtiqueta) return false

        if (config?.ultimasUnidades) {
            if (campaign.stock > 9) return false
        }
        /*
if (config?.bajadaPrecio) {
return false
}
*/

        if (filterByBrand) {
            if (config.marca.indexOf(campaign.brand) < 0) return false
        }

        if (filterByPrice) {
            let isValid = false
            if (config.precio[3] && quoteWithoutVAT > 450) isValid = true
            if (config.precio[1] && quoteWithoutVAT >= 250 && quoteWithoutVAT <= 300) isValid = true
            if (config.precio[2] && quoteWithoutVAT >= 300 && quoteWithoutVAT <= 450) isValid = true
            if (config.precio[0] && quoteWithoutVAT < 250) isValid = true
            if (!isValid) return false
        }

        if (campaign.stock > 0) return true

        return false
    })

    campaigns.sort((a, b) => {
        const defaultTermA = a.defaultTerm
        const defaultTermB = b.defaultTerm
        const defaultMileageA = a.defaultMileage
        const defaultMileageB = b.defaultMileage

        if (
            a.quotes['ucl'][defaultTermA][defaultMileageA].monthlyQuote >
            b.quotes['ucl'][defaultTermB][defaultMileageB].monthlyQuote
        )
            return 1
        if (
            a.quotes['ucl'][defaultTermA][defaultMileageA].monthlyQuote <
            b.quotes['ucl'][defaultTermB][defaultMileageB].monthlyQuote
        )
            return -1
        return 0
    })

    result.campaigns = campaigns

    if (config.perfil === 'particulares') result.campaigns = []

    return result
}

export const Comerciales2 = ['COMERCIAL DERIVADO TURISM', 'FURGON GRANDE', 'CHASIS CABINA', 'FURGON MEDIO']
const SUV2 = ['TT MEDIO', 'TT PEQUEÑO']
const Berlina2 = ['TURISMO MEDIO ALTO']
const Compacto2 = ['TURISMO MEDIO BAJO']
const Monovolumen2 = ['TURISMO MONOVOLUMEN']
const Turismo2 = ['TURISMO PEQUEÑO', 'TURISMO PEQUEÑO BAJO']

export const filterCampaigns = function (campaigns, config) {
    // TODO: Tipos de ordenación

    if (!config.whiteList) config.whiteList = []
    if (!config?.whiteListBrand) config.whiteListBrand = []

    const SUV = ['OD', 'PU']
    const Berlina = ['HA', 'SA']

    const Premium = [
        'AUDI',
        'BMW',
        'LAND ROVER',
        'MINI',
        'MERCEDES BENZ',
        'LEXUS',
        'INFINITY',
        'TESLA',
        'ASTON MARTIN',
        'BENTLEY',
        'FERRARI',
        'LAMBORGHINI ',
        'JAGUAR',
        'MASERATI',
        'MCLAREN',
        'PORSCHE',
        'ROLLS ROYCE',
    ]

    let result = {
        campaigns: [],
        brands: [],
        allBrands: [],
    }

    if (config?.tipoVehiculo?.[1] === true && config?.tipoVehiculo?.[0] === false) return result

    const filterByTiempoUso = config?.tiempoUso ? !Object.values(config.tiempoUso).every((x) => x === false) : false
    if (filterByTiempoUso && config?.tiempoUso[3] === false) return result

    campaigns = campaigns.filter((campaign) => {
        if (campaign?.productType === 'flexiblesuscripcion' || campaign?.productType === 'flexible10') return false
        const defaultProduct = getDefaultProduct(campaign)
        const defaultMileage = getDefaultMileage(campaign)
        const defaultTerm = getDefaultTerm(campaign)
        const quoteWithoutVAT = campaign?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        if (config.specialFilter === '72meses' && ofertas60Aniversario.indexOf(campaign.campaignID) === -1) return false
        return quoteWithoutVAT !== null && quoteWithoutVAT !== undefined
    })

    // First order by price
    campaigns.sort((a, b) => {
        const defaultProductA = getDefaultProduct(a)
        const defaultProductB = getDefaultProduct(b)
        if (a.cheapest[defaultProductA]?.quote > b.cheapest[defaultProductB]?.quote) return 1
        if (a.cheapest[defaultProductA]?.quote < b.cheapest[defaultProductB]?.quote) return -1
        return 0
    })

    const filterByPrice = !Object.values(config.precio).every((x) => x === false)
    const filterByFuel = !Object.values(config.combustible).every((x) => x === false)
    const filterByEtiqueta = !Object.values(config.etiqueta || {}).every((x) => x === false)
    const filterByGear = !Object.values(config.cambio).every((x) => x === false)
    const filterBySegmento = !Object.values(config.segmento).every((x) => x === false)
    const filterByBrand = config.marca.length > 0

    campaigns = campaigns.map((campaign) => {
        // TODO: Should be done offline
        if (Overrides[campaign.campaignID]) {
            campaign = mergeObjects(campaign, Overrides[campaign.campaignID])
        }
        return campaign
    })

    campaigns = campaigns.filter((campaign) => {
        if (
            campaign?.stockByPrescriptor &&
            (!campaign?.stockByPrescriptor?.['default'] || campaign?.stockByPrescriptor?.['default'] === 0)
        )
            return false
        if (campaign.brand !== 'Turismo' && campaign.brand !== 'Vehículos') result.allBrands.push(campaign.brand)

        if (config?.etiquetasEcologicos) {
            if (!campaign.eco && !campaign.electric && !campaign.pluginhybrid && !campaign.cero) return false
        }

        const defaultProduct = getDefaultProduct(campaign)
        const defaultMileage = getDefaultMileage(campaign)
        const defaultTerm = getDefaultTerm(campaign)
        const quoteWithoutVAT = campaign.quotes[defaultProduct][defaultTerm][defaultMileage].monthlyQuote
        const model = `${campaign.brand}-${campaign.model}`.toUpperCase()

        if (config.perfil === 'particulares') {
            if (industrial.indexOf(campaign.nolsBodyType) > -1) return false
            if (
                whiteList.individual.model.indexOf(model) === -1 &&
                whiteList.individual.campaigns.indexOf(campaign.campaignID) === -1 &&
                config.whiteList.indexOf(campaign.campaignID) === -1 &&
                config.whiteListBrand.indexOf(campaign.brand) === -1
            ) {
                if (blackList.individual.brands.indexOf(campaign.brand.toUpperCase()) > -1) return false
                if (blackList.individual.campaigns.indexOf(campaign.campaignID) > -1) return false
                if (blackList.individual.model.indexOf(model) > -1) return false
            }
        } else {
            if (campaign?.onlyIndividuals) return false
        }

        if (config.perfil === 'autonomos') {
            if (
                whiteList.soletrader.model.indexOf(model) === -1 &&
                whiteList.soletrader.campaigns.indexOf(campaign.campaignID) === -1 &&
                config.whiteList.indexOf(campaign.campaignID) === -1 &&
                config.whiteListBrand.indexOf(campaign.brand) === -1
            ) {
                if (blackList?.soletrader?.brands.indexOf(campaign.brand) > -1) return false
                if (blackList?.soletrader?.campaigns.indexOf(campaign.campaignID) > -1) return false
            }
        }
        if (config.perfil === 'empresas') {
            if (
                whiteList.sme.model.indexOf(model) === -1 &&
                whiteList.sme.campaigns.indexOf(campaign.campaignID) === -1 &&
                config.whiteList.indexOf(campaign.campaignID) === -1 &&
                config.whiteListBrand.indexOf(campaign.brand) === -1
            ) {
                if (blackList?.sme?.brands.indexOf(campaign.brand) > -1) return false
                if (blackList?.sme?.campaigns.indexOf(campaign.campaignID) > -1) return false
            }
        }

        if (config?.entregaRapida && !config?.bajadaDePrecio) {
            if (campaign.stockRegistered < 1) return false
        }

        if (config?.bajadaPrecio && !config?.entregaRapida) {
            if (!campaign.quotes[defaultProduct][defaultTerm][defaultMileage]?.oldMonthlyQuote) return false
        }

        if (config?.ultimasUnidades) {
            if (campaign.stock > 9) return false
        }
        /*
Sobra?
if (config.perfil === 'autonomos') {
if (blackList.soletrader.campaigns.indexOf(campaign.campaignID) > -1) return false
}
*/

        if (filterByPrice) {
            let isValid = false
            if (config.precio[3] && quoteWithoutVAT > 450) isValid = true
            if (config.precio[1] && quoteWithoutVAT >= 250 && quoteWithoutVAT <= 300) isValid = true
            if (config.precio[2] && quoteWithoutVAT >= 300 && quoteWithoutVAT <= 450) isValid = true
            if (config.precio[0] && quoteWithoutVAT < 250) isValid = true
            if (!isValid) return false
        }
        if (filterByGear) {
            let isValid = false
            if (config.cambio[1] && getPrettyGear(campaign) === 'Manual') isValid = true
            if (config.cambio[0] && getPrettyGear(campaign) === 'Automático') isValid = true
            if (!isValid) return false
        }

        if (filterByFuel) {
            let isValid = false
            if (config.combustible[0] && getPrettyFuel(campaign) === 'Diesel') isValid = true
            if (config.combustible[1] && getPrettyFuel(campaign) === 'Gasolina') isValid = true
            if (config.combustible[2] && getPrettyFuel(campaign) === 'Eléctrico') isValid = true
            if (
                config.combustible[3] &&
                (getPrettyFuel(campaign) === 'Híbrido' || getPrettyFuel(campaign) === 'Híbrido Enchufable')
            )
                isValid = true
            if (!isValid) return false
        }

        if (filterByEtiqueta) {
            let isValid = false
            if (
                config?.etiqueta[0] &&
                (campaign.electric || campaign.pluginhybrid || campaign.cero || getPrettyFuel(campaign) === 'Eléctrico')
            )
                isValid = true
            if (config?.etiqueta[1] && campaign.eco) isValid = true
            if (!isValid) return false
        }

        if (filterByBrand) {
            if (config.marca.indexOf(campaign.brand) < 0) return false
        }

        if (filterBySegmento) {
            let isValid = false
            if (config.segmento?.['0'] && SUV2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['1'] && Comerciales2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['2'] && Berlina2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['4'] && Monovolumen2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['5'] && Compacto2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['6'] && Turismo2.indexOf(campaign.segment) > -1) isValid = true
            if (config.segmento?.['3'] && Premium.indexOf(campaign.brand.toUpperCase()) > -1) isValid = true
            if (!isValid) return false
        }

        return true
    })

    const alreadyDone = {}
    result.campaigns = campaigns.map((campaign) => {
        // Pasamos a usar stock parcelado
        if (campaign?.stockByPrescriptor?.['default']?.total < 1) return null
        if (!campaign.stockByPrescriptor && campaign.stock < 1) return null

        const model = `${campaign.brand}-${campaign.model}`.toUpperCase()
        const brand = `${campaign.brand}`.toUpperCase()
        let brandModelFuel =
            `${campaign.brand}-${campaign.model}-${campaign.fuelPrimary}-${campaign.hybrid}-${campaign.hybridlite}-${campaign.nolsBodyType}`.toUpperCase()
        if (config.alreadyDoneStrategy === 'brandModelFuelStrategy') {
            brandModelFuel = `${campaign.brand}-${campaign.model}-${campaign.fuelPrimary}`.toUpperCase()
        }

        if (
            whiteList?.global?.campaigns.indexOf(campaign.campaignID) === -1 &&
            config?.whiteList?.indexOf(campaign.campaignID) === -1 &&
            config?.whiteListBrand.indexOf(campaign.brand) === -1
        ) {
            if (blackList?.global?.model.indexOf(model) > -1) {
                // console.log('model globally banned', model)
                return null
            }
            if (blackList?.global?.campaigns.indexOf(campaign.campaignID) > -1) {
                // console.log('campaign globally banned', campaign.campaignID)
                return null
            }
            if (blackList?.global?.brand.indexOf(campaign.brand) > -1) {
                // console.log('brand globally banned', campaign.brand)
                return null
            }
        }

        /*
if (movistarCarList?.model.indexOf(model) > -1) {
campaign.movistarcar = true
}
*/

        if (specialPrice?.indexOf(campaign.campaignID) > -1) {
            campaign.specialPrice = true
        }

        if (alreadyDone[brandModelFuel]) {
            return null
        } else {
            alreadyDone[brandModelFuel] = true
        }

        if (campaign.brand !== 'Turismo' && campaign.brand !== 'Vehículos') result.brands.push(campaign.brand)
        return campaign
    })

    // Remove null values
    result.campaigns = result.campaigns.filter(Boolean)

    result.brands = [...new Set(result.brands)].sort()
    result.allBrands = [...new Set(result.allBrands)].sort()

    return result
}

export const sortByFeatured = (campaigns, perfil, activeProduct, destacadosLocal) => {
    if (activeProduct === 'ucl') {
        return sortByFeaturedUCL(campaigns)
    }

    if (perfil === 'empresas') {
        if (destacadosLocal) return sortByManual(campaigns, destacadosLocal, perfil)
        return sortByFeaturedSME(campaigns)
    } else if (perfil === 'autonomos') {
        if (destacadosLocal) return sortByManual(campaigns, destacadosLocal, perfil)
        const excludeSME = getExcludeArray(sortByFeaturedSME(campaigns))
        const excludeSoleTrader = getExcludeArray(sortByFeaturedIndividual(campaigns, excludeSME))
        return sortByFeaturedSoleTrader(campaigns, [...excludeSME, excludeSoleTrader])
    } else if (perfil === 'particulares') {
        if (destacadosLocal) return sortByManual(campaigns, destacadosLocal, perfil)
        const exclude = getExcludeArray(sortByFeaturedSME(campaigns))
        return sortByFeaturedIndividual(campaigns, exclude)
    } else {
        return sortByFeaturedHome(campaigns)
    }
    const perfilProduct = activeProduct === 'upfront' ? `${perfil}-upfront` : perfil

    campaigns.sort((a, b) => {
        const brandModelFuelA = `${a.brand}-${a.model}-${a?.fuelPrimary}`.toUpperCase()
        const brandModelFuelB = `${b.brand}-${b.model}-${b?.fuelPrimary}`.toUpperCase()
        let posA = 9999
        let posB = 9999

        if (
            Destacados[perfilProduct].indexOf(brandModelFuelA) > -1 &&
            blackList.destacados['home'].indexOf(a.campaignID) === -1
        ) {
            posA = Destacados[perfilProduct].indexOf(brandModelFuelA)
        }
        if (
            Destacados[perfilProduct].indexOf(brandModelFuelB) > -1 &&
            blackList.destacados['home'].indexOf(b.campaignID) === -1
        ) {
            posB = Destacados[perfilProduct].indexOf(brandModelFuelB)
        }

        if (posA < posB) return -1
        if (posA > posB) return 1
        return 0
    })
    return campaigns
}

export const sortByFeaturedSME = (campaigns) => {
    let prioritize = []
    const brandCounter = {}

    // Look for campaigns by conditions
    campaigns.map((campaign) => {
        if (whiteList.global.prioritize.indexOf(campaign.campaignID) > -1) {
            const defaultProduct = getDefaultProduct(campaign)
            const defaultMileage = getDefaultMileage(campaign)
            const defaultTerm = getDefaultTerm(campaign)
            const quoteWithoutVAT = campaign?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
            const campaignID = campaign.campaignID
            const brand = campaign.brand.toUpperCase()
            brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
            prioritize.push({
                campaignID: campaignID,
                quoteWithoutVAT: quoteWithoutVAT,
                priority: true,
            })
        }
    })

    const ttMedios = filterBySegment(campaigns, 'TT MEDIO', false)
    // Add the to the priority list
    if (ttMedios.length > 0) {
        const defaultProduct = getDefaultProduct(ttMedios[0])
        const defaultMileage = getDefaultMileage(ttMedios[0])
        const defaultTerm = getDefaultTerm(ttMedios[0])
        const quoteWithoutVAT = ttMedios[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = ttMedios[0].campaignID
        const brand = ttMedios[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }

    const ttMediosPremium = filterBySegment(campaigns, 'TT MEDIO', true, null, brandCounter)
    if (ttMediosPremium.length > 0) {
        const defaultProduct = getDefaultProduct(ttMediosPremium[0])
        const defaultMileage = getDefaultMileage(ttMediosPremium[0])
        const defaultTerm = getDefaultTerm(ttMediosPremium[0])
        const quoteWithoutVAT =
            ttMediosPremium[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = ttMediosPremium[0].campaignID
        const brand = ttMediosPremium[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const ttPequeno = filterBySegment(campaigns, 'TT PEQUEÑO', null, null, brandCounter)
    if (ttPequeno.length > 0) {
        const defaultProduct = getDefaultProduct(ttPequeno[0])
        const defaultMileage = getDefaultMileage(ttPequeno[0])
        const defaultTerm = getDefaultTerm(ttPequeno[0])
        const quoteWithoutVAT = ttPequeno[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = ttPequeno[0].campaignID
        const brand = ttPequeno[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const tuMedioBajo = filterBySegment(campaigns, 'TURISMO MEDIO BAJO', null, null, brandCounter)
    if (tuMedioBajo.length > 0) {
        const defaultProduct = getDefaultProduct(tuMedioBajo[0])
        const defaultMileage = getDefaultMileage(tuMedioBajo[0])
        const defaultTerm = getDefaultTerm(tuMedioBajo[0])
        const quoteWithoutVAT = tuMedioBajo[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = tuMedioBajo[0].campaignID
        const brand = tuMedioBajo[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const industriales = filterByNolsBodyType(campaigns, industrial)
    if (industriales.length > 0) {
        const defaultProduct = getDefaultProduct(industriales[0])
        const defaultMileage = getDefaultMileage(industriales[0])
        const defaultTerm = getDefaultTerm(industriales[0])
        const quoteWithoutVAT = industriales[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = industriales[0].campaignID
        const brand = industriales[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }

    // Order prioritized campaigns by price
    prioritize.sort((a, b) => {
        if (a.priority === true && !b?.priority) return -1
        if (!a?.priority && b.priority === true) return 1

        if (a?.quoteWithoutVAT > b?.quoteWithoutVAT) return 1
        if (a?.quoteWithoutVAT < b?.quoteWithoutVAT) return -1
        return 0
    })

    // Order all campaigns taking into account the prioritized ones
    campaigns.sort((a, b) => {
        const brandModelFuelA = a.campaignID
        const brandModelFuelB = b.campaignID
        let posA = 9999
        let posB = 9999

        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            }) > -1
        ) {
            posA = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            })
        }
        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            }) > -1
        ) {
            posB = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            })
        }

        if (posA < posB) return -1
        if (posA > posB) return 1
        return 0
    })

    return campaigns
}

export const sortByFeaturedSoleTrader = (campaigns, excludeList) => {
    let prioritize = []
    const brandCounter = {}

    // Look for campaigns by conditions
    campaigns.map((campaign) => {
        if (whiteList.global.prioritize.indexOf(campaign.campaignID) > -1) {
            const defaultProduct = getDefaultProduct(campaign)
            const defaultMileage = getDefaultMileage(campaign)
            const defaultTerm = getDefaultTerm(campaign)
            const quoteWithoutVAT = campaign?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
            const campaignID = campaign.campaignID
            const brand = campaign.brand.toUpperCase()
            brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
            prioritize.push({
                campaignID: campaignID,
                quoteWithoutVAT: quoteWithoutVAT,
                priority: true,
            })
        }
    })

    const ttMedios = filterBySegment(campaigns, 'TT MEDIO', null, excludeList, brandCounter)

    // Add the to the priority list
    if (ttMedios.length > 0) {
        const defaultProduct = getDefaultProduct(ttMedios[0])
        const defaultMileage = getDefaultMileage(ttMedios[0])
        const defaultTerm = getDefaultTerm(ttMedios[0])
        const quoteWithoutVAT = ttMedios[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = ttMedios[0].campaignID
        const brand = ttMedios[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const ttMediosPremium = filterBySegment(campaigns, 'TT MEDIO', true, excludeList, brandCounter)
    if (ttMediosPremium.length > 0) {
        const defaultProduct = getDefaultProduct(ttMediosPremium[0])
        const defaultMileage = getDefaultMileage(ttMediosPremium[0])
        const defaultTerm = getDefaultTerm(ttMediosPremium[0])
        const quoteWithoutVAT =
            ttMediosPremium[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = ttMediosPremium[0].campaignID
        const brand = ttMediosPremium[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const tuMedioBajo = filterBySegment(campaigns, 'TURISMO MEDIO BAJO', null, excludeList, brandCounter)
    if (tuMedioBajo.length > 0) {
        const defaultProduct = getDefaultProduct(tuMedioBajo[0])
        const defaultMileage = getDefaultMileage(tuMedioBajo[0])
        const defaultTerm = getDefaultTerm(tuMedioBajo[0])
        const quoteWithoutVAT = tuMedioBajo[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = tuMedioBajo[0].campaignID
        const brand = tuMedioBajo[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const industriales = filterByNolsBodyType(campaigns, industrial, null, excludeList, brandCounter)
    if (industriales.length > 0) {
        const defaultProduct = getDefaultProduct(industriales[0])
        const defaultMileage = getDefaultMileage(industriales[0])
        const defaultTerm = getDefaultTerm(industriales[0])
        const quoteWithoutVAT = industriales[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = industriales[0].campaignID
        const brand = industriales[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const noTTPremium = filterBySegment(campaigns, 'TURISMO MEDIO ALTO', true, excludeList, brandCounter)
    if (noTTPremium.length > 0) {
        const defaultProduct = getDefaultProduct(noTTPremium[0])
        const defaultMileage = getDefaultMileage(noTTPremium[0])
        const defaultTerm = getDefaultTerm(noTTPremium[0])
        const quoteWithoutVAT = noTTPremium[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = noTTPremium[0].campaignID
        const brand = noTTPremium[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }

    // Order prioritized campaigns by price
    prioritize.sort((a, b) => {
        if (a.priority === true && !b?.priority) return -1
        if (!a?.priority && b.priority === true) return 1

        if (a?.quoteWithoutVAT > b?.quoteWithoutVAT) return 1
        if (a?.quoteWithoutVAT < b?.quoteWithoutVAT) return -1
        return 0
    })

    // Order all campaigns taking into account the prioritized ones
    campaigns.sort((a, b) => {
        const brandModelFuelA = a.campaignID
        const brandModelFuelB = b.campaignID
        let posA = 9999
        let posB = 9999

        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            }) > -1
        ) {
            posA = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            })
        }
        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            }) > -1
        ) {
            posB = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            })
        }

        if (posA < posB) return -1
        if (posA > posB) return 1
        return 0
    })

    return campaigns
}

export const sortByFeaturedUCL = (campaigns, excludeList) => {
    let prioritize = []

    // Add the to the priority list
    campaigns.map((campaign) => {
        if (['Urbanos', 'Compactos'].indexOf(campaign.model) > -1) {
            const defaultProduct = getDefaultProduct(campaign)
            const defaultMileage = getDefaultMileage(campaign)
            const defaultTerm = getDefaultTerm(campaign)
            const quoteWithoutVAT = campaign?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote

            prioritize.push({
                campaignID: campaign.campaignID,
                quoteWithoutVAT: quoteWithoutVAT,
                priority: true,
            })
        }
    })

    // Order prioritized campaigns by price
    prioritize.sort((a, b) => {
        // if (a.priority === true && !b?.priority) return -1
        // if (!a?.priority && b.priority === true) return 1

        if (a?.quoteWithoutVAT > b?.quoteWithoutVAT) return 1
        if (a?.quoteWithoutVAT < b?.quoteWithoutVAT) return -1
        return 0
    })

    // Order all campaigns taking into account the prioritized ones
    campaigns.sort((a, b) => {
        let posA = 9999
        let posB = 9999

        if (
            prioritize.findIndex((item) => {
                return item.campaignID === a.campaignID
            }) > -1
        ) {
            posA = prioritize.findIndex((item) => {
                return item.campaignID === a.campaignID
            })
        }
        if (
            prioritize.findIndex((item) => {
                return item.campaignID === b.campaignID
            }) > -1
        ) {
            posB = prioritize.findIndex((item) => {
                return item.campaignID === b.campaignID
            })
        }

        if (posA < posB) return -1
        if (posA > posB) return 1
        return 0
    })

    return campaigns
}

export const sortByFeaturedIndividual = (campaigns, excludeList) => {
    let prioritize = []
    const brandCounter = {}
    // Look for campaigns by conditions
    const ttMedios = filterBySegment(campaigns, 'TT MEDIO', null, excludeList, brandCounter)
    let exclude = []

    // Add the to the priority list
    campaigns.map((campaign) => {
        if (whiteList.global.prioritize.indexOf(campaign.campaignID) > -1) {
            const defaultProduct = getDefaultProduct(campaign)
            const defaultMileage = getDefaultMileage(campaign)
            const defaultTerm = getDefaultTerm(campaign)
            const quoteWithoutVAT = campaign?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
            const campaignID = campaign.campaignID
            const brand = campaign.brand.toUpperCase()
            brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
            prioritize.push({
                campaignID: campaignID,
                quoteWithoutVAT: quoteWithoutVAT,
                priority: true,
            })
        }
    })

    if (ttMedios.length > 0) {
        const defaultProduct = getDefaultProduct(ttMedios[0])
        const defaultMileage = getDefaultMileage(ttMedios[0])
        const defaultTerm = getDefaultTerm(ttMedios[0])
        const quoteWithoutVAT = ttMedios[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = ttMedios[0].campaignID
        const brand = ttMedios[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        exclude.push(campaignID)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const tuPequeno = filterBySegment(campaigns, 'TURISMO PEQUEÑO', null, excludeList, brandCounter)
    if (tuPequeno.length > 0) {
        const defaultProduct = getDefaultProduct(tuPequeno[0])
        const defaultMileage = getDefaultMileage(tuPequeno[0])
        const defaultTerm = getDefaultTerm(tuPequeno[0])
        const quoteWithoutVAT = tuPequeno[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = tuPequeno[0].campaignID
        const brand = tuPequeno[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        exclude.push(campaignID)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    const ttPequeno = filterBySegment(campaigns, 'TT PEQUEÑO', null, excludeList, brandCounter)
    if (ttPequeno.length > 0) {
        const defaultProduct = getDefaultProduct(ttPequeno[0])
        const defaultMileage = getDefaultMileage(ttPequeno[0])
        const defaultTerm = getDefaultTerm(ttPequeno[0])
        const quoteWithoutVAT = ttPequeno[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = ttPequeno[0].campaignID
        const brand = ttPequeno[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        exclude.push(campaignID)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }

    let registered = filterByRegistered(campaigns, exclude, excludeList, brandCounter)

    if (registered.length > 0) {
        const defaultProduct = getDefaultProduct(registered[0])
        const defaultMileage = getDefaultMileage(registered[0])
        const defaultTerm = getDefaultTerm(registered[0])
        const quoteWithoutVAT = registered[0]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = registered[0].campaignID
        const brand = registered[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }
    if (registered.length > 1) {
        const defaultProduct = getDefaultProduct(registered[1])
        const defaultMileage = getDefaultMileage(registered[1])
        const defaultTerm = getDefaultTerm(registered[1])
        const quoteWithoutVAT = registered[1]?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = registered[1].campaignID
        const brand = registered[0].brand.toUpperCase()
        brandCounter[brand] ? brandCounter[brand]++ : (brandCounter[brand] = 1)
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    }

    // Order prioritized campaigns by price
    prioritize.sort((a, b) => {
        if (a.priority === true && !b?.priority) return -1
        if (!a?.priority && b.priority === true) return 1

        if (a?.quoteWithoutVAT > b?.quoteWithoutVAT) return 1
        if (a?.quoteWithoutVAT < b?.quoteWithoutVAT) return -1
        return 0
    })

    // Order all campaigns taking into account the prioritized ones
    campaigns.sort((a, b) => {
        const brandModelFuelA = a.campaignID
        const brandModelFuelB = b.campaignID
        let posA = 9999
        let posB = 9999

        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            }) > -1
        ) {
            posA = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            })
        }
        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            }) > -1
        ) {
            posB = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            })
        }

        if (posA < posB) return -1
        if (posA > posB) return 1
        return 0
    })

    return campaigns
}

export const sortByFeaturedHome = (campaigns) => {
    let prioritize = []

    campaigns.map((campaign) => {
        if (whiteList.global.prioritize.indexOf(campaign.campaignID) > -1) {
            const defaultProduct = getDefaultProduct(campaign)
            const defaultMileage = getDefaultMileage(campaign)
            const defaultTerm = getDefaultTerm(campaign)
            const quoteWithoutVAT = campaign?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
            const campaignID = campaign.campaignID
            prioritize.push({
                campaignID: campaignID,
                quoteWithoutVAT: quoteWithoutVAT,
                priority: true,
            })
        }
    })

    let registered = filterByRegistered(campaigns, []).slice(0, 7)

    registered.map((campaign) => {
        const defaultProduct = getDefaultProduct(campaign)
        const defaultMileage = getDefaultMileage(campaign)
        const defaultTerm = getDefaultTerm(campaign)
        const quoteWithoutVAT = campaign?.quotes[defaultProduct]?.[defaultTerm]?.[defaultMileage]?.monthlyQuote
        const campaignID = campaign.campaignID
        prioritize.push({
            campaignID: campaignID,
            quoteWithoutVAT: quoteWithoutVAT,
        })
    })

    // Order prioritized campaigns by price
    prioritize.sort((a, b) => {
        if (a.priority === true && !b?.priority) return -1
        if (!a?.priority && b.priority === true) return 1

        if (a?.quoteWithoutVAT > b?.quoteWithoutVAT) return 1
        if (a?.quoteWithoutVAT < b?.quoteWithoutVAT) return -1
        return 0
    })

    // Order all campaigns taking into account the prioritized ones
    campaigns.sort((a, b) => {
        const brandModelFuelA = a.campaignID
        const brandModelFuelB = b.campaignID
        let posA = 9999
        let posB = 9999

        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            }) > -1
        ) {
            posA = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelA
            })
        }
        if (
            prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            }) > -1
        ) {
            posB = prioritize.findIndex((item) => {
                return item.campaignID === brandModelFuelB
            })
        }

        if (posA < posB) return -1
        if (posA > posB) return 1
        return 0
    })

    return campaigns
}

const filterBySegment = (campaigns, segment, shouldBePremium, excludeList, brandCounter) => {
    return campaigns.filter((campaign) => {
        if (brandCounter?.[campaign.brand] > 2) return false
        const description = `${campaign.brand.toUpperCase()}-${campaign.model.toUpperCase()}`
        if (excludeList && excludeList.indexOf(description) > -1) return false
        if (shouldBePremium === undefined) {
            return campaign.segment === segment
        } else {
            if (shouldBePremium && premiumBrands.indexOf(campaign.brand) > -1) {
                return campaign.segment === segment
            } else if (!shouldBePremium && premiumBrands.indexOf(campaign.brand) === -1) {
                return campaign.segment === segment
            }
        }
    })
}

const filterByNolsBodyType = (campaigns, nolsBodyTypes, brandCounter) => {
    return campaigns.filter((campaign) => {
        const description = `${campaign.brand.toUpperCase()}-${campaign.model.toUpperCase()}`
        if (brandCounter?.[campaign.brand] > 2) return false
        return nolsBodyTypes.indexOf(campaign.nolsBodyType) > -1
    })
}

const filterByRegistered = (campaigns, excludeCampaignIDs, excludeList) => {
    if (excludeCampaignIDs === null || excludeCampaignIDs === undefined) exclude = []
    return campaigns.filter((campaign) => {
        const description = `${campaign.brand.toUpperCase()}-${campaign.model.toUpperCase()}`
        if (excludeList && excludeList.indexOf(description) > -1) return false
        return (
            campaign?.stockByPrescriptor?.['default'].registered > 0 &&
            excludeCampaignIDs.indexOf(campaign.campaignID) === -1
        )
    })
}

const getExcludeArray = (campaigns) => {
    let exclude = []
    campaigns = campaigns.slice(0, 7)
    for (const campaign of campaigns) {
        exclude.push(`${campaign.brand.toUpperCase()}-${campaign.model.toUpperCase()}`)
    }
    return exclude
}

export const sortByManual = (campaigns, destacados, tipologia) => {
    tipologia = tipologia ? tipologia : 'home'
    return campaigns.sort((a, b) => {
        const brandModelFuelA = `${a.brand}-${a.model}-${a?.fuelPrimary}`.toUpperCase()
        const brandModelFuelB = `${b.brand}-${b.model}-${b?.fuelPrimary}`.toUpperCase()
        let posA = 9999
        let posB = 9999

        if (destacados.indexOf(brandModelFuelA) > -1 && blackList?.destacados[tipologia].indexOf(a.campaignID) === -1)
            posA = destacados.indexOf(brandModelFuelA)
        if (destacados.indexOf(brandModelFuelB) > -1 && blackList?.destacados[tipologia].indexOf(b.campaignID) === -1)
            posB = destacados.indexOf(brandModelFuelB)

        if (posA < posB) return -1
        if (posA > posB) return 1
        return 0
    })
}
